import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import * as footerStyles from "../components/footer.module.scss"
import email from "../assets/icons/email.svg"
import linkedin from "../assets/icons/linkedin.svg"
import facebook from "../assets/icons/facebook.svg"
import homelife from "../assets/icons/realestate2.svg"
import { v4 as uuidv4 } from "uuid"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)
  const year = new Date().getFullYear() 
  const menuItems = [
    { page: "about", link: "/about" },
    {
      page: "real estate",
      link: "/realEstate",
    },
    { page: "diorite", link: "/diorite" },
    { page: "contact", link: "/contact" },
  ]
  const socialItems = [
    {
      altText: "Click here to send Richard an email",
      link: "mailto: richardfababeir@yahoo.ca",
      icon: email,
    },
    {
      altText: "Click here for Richard's LinkedIn",
      link: "https://www.linkedin.com/in/richardfababeir/",
      icon: linkedin,
    },
    {
      altText: "Click here for Richard's facebook",
      link: "https://www.facebook.ca",
      icon: facebook,
    },
    {
      altText: "Click here for Richard's Home Life Brokerage page",
      link: "https://www.homelife.ca/Richard-Fababeir",
      icon: homelife,
    },
  ]
  return (
    <footer className={footerStyles.footer}>
      <div>
        <div className={footerStyles.logo}>
          <h4 className={footerStyles.logo__name}>Richard</h4>
          <h4 className={footerStyles.logo__name}>Fababeir</h4>
        </div>
        <ul className={footerStyles.menu}>
          {menuItems.map(item => {
            return (
              <li className={footerStyles.menu__listItem} key={uuidv4()}>
                <Link to={item.link}>{item.page}</Link>
              </li>
            )
          })}
        </ul>

        <ul className={footerStyles.social}>
          {socialItems.map(item => {
            return (
              <li className={footerStyles.social__media} key={uuidv4()}>
                <a href={item.link} target="blank">
                  <img
                    src={item.icon}
                    alt={item.altText}
                    className={footerStyles.social__icon}
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <p className={footerStyles.footer__copyright}>
        Created by{" "}
        <a
          className={footerStyles.footer__link}
          href="https://lovefababeir.com/"
          target="blank"
        >
          {data.site.siteMetadata.author}
        </a>{" "}
        - Copyright {year}
      </p>
    </footer>
  )
}

export default Footer

import React from "react"
import HeaderMenuItem from "./headerMenuItem.js"
import * as styles from "./headerMenu.module.scss"

const HeaderMenu = () => {
  const listItems = [
    { page: "about", link: "/about", className: styles.menu__listItem },
    {
      page: "real estate",
      link: "/realEstate",
      className: styles.menu__listItem,
    },
    { page: "fababeir", link: "/about", className: styles.menu__logo },
    { page: "diorite", link: "/diorite", className: styles.menu__listItem },
    { page: "contact", link: "/contact", className: styles.menu__listItem },
  ]

  return (
    <nav className={styles.menu}>
      <ul className={styles.menu__list}>
        <span>
          <HeaderMenuItem styles={styles} page={listItems[0]} />
          <HeaderMenuItem styles={styles} page={listItems[1]} />
        </span>
        <HeaderMenuItem styles={styles} page={listItems[2]} />
        <span>
          <HeaderMenuItem styles={styles} page={listItems[3]} />
          <HeaderMenuItem styles={styles} page={listItems[4]} />
        </span>
      </ul>
    </nav>
  )
}

export default HeaderMenu

import React from "react"
import Layout from "../components/layout.js"
import { graphql } from "gatsby"
import "../templates/blogPosts.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

//--------------------------
//USING CONTENTFUL:
//notice how query uses conteful... not allContentful
//--------------------------
export const query = graphql`
  query($slug: String!) {
    contentfulRichardsRealEstate(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "MMMM D YYYY")
      description {
        raw
        references {
          title
          description
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
  }
`

const BlogPosts = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": () => {
        const alt =
          props.data.contentfulRichardsRealEstate.description.references[0]
            .description
        const url =
          props.data.contentfulRichardsRealEstate.description.references[0].file
            .url
        return <img className="img-fluid" alt={alt} src={url} />
      },
    },
  }

  return (
    <Layout>
      <div className="post">
        <h1 className="post__title">Real Estate in</h1>
        <h1 className="post__title-region">
          {props.data.contentfulRichardsRealEstate.title}
        </h1>
        <p className="post__date">
          last update on: {props.data.contentfulRichardsRealEstate.date}
        </p>
        <div className="post__content">
          {documentToReactComponents(
            JSON.parse(props.data.contentfulRichardsRealEstate.description.raw),
            options
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPosts

//--------------------------
//Using markdownRemark
//--------------------------
// export const query = graphql`
//   query($slug: String!) {
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       frontmatter {
//         title
//         date
//       }
//       html
//     }
//   }
// `

// const BlogPosts = props => {
//   console.log(props.data)
//   return (
//     <Layout>
//       <div className="blogPosts">
//         <h1>{props.data.markdownRemark.frontmatter.title}</h1>
//         <p>{props.data.markdownRemark.frontmatter.date}</p>
//         <div
//           dangerouslySetInnerHTML={{
//             __html: props.data.markdownRemark.html,
//           }}
//           className="blogPosts__content"
//         ></div>
//       </div>
//     </Layout>
//   )
// }

// export default BlogPosts

import React from "react"
import { Link } from "gatsby"
const HeaderMenuItem = ({ styles, page }) => {
  return (
    <li>
      <Link
        to={page.link}
        className={page.className}
        activeClassName={
          page.page === "fababeir" ? "" : styles.menu__activeItem
        }
      >
        {page.page}
      </Link>
    </li>
  )
}

export default HeaderMenuItem

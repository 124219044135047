import React from "react"
import * as styles from "./header.module.scss"
import HeaderMenu from "./headerMenu.js"

const Header = () => {
  return (
    <header className={styles.header}>
      <p className={styles.header__logo}>FABABEIR</p>
      <HeaderMenu />
    </header>
  )
}

export default Header

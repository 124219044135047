// extracted by mini-css-extract-plugin
export const footer = "footer-module--footer--3L5Rb";
export const footer__copyright = "footer-module--footer__copyright--FnwLV";
export const footer__link = "footer-module--footer__link--3DFiG";
export const social = "footer-module--social--2l7-4";
export const social__media = "footer-module--social__media--tiMGT";
export const social__icon = "footer-module--social__icon--37joZ";
export const menu = "footer-module--menu--2SfYd";
export const menu__listItem = "footer-module--menu__listItem--1GqGF";
export const logo = "footer-module--logo--nonT3";
export const logo__name = "footer-module--logo__name--fhZI-";
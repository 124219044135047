import React from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
// import * as layoutStyles from "../components/layout.module.scss"

const Layout = props => {
  return (
    <section className={`${props.page} richardsWebsite`}>
      <Header page={props.page} />
      {props.children}
      <Footer />
    </section>
  )
}

export default Layout
